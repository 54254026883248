import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu:any=true; 
  public studentData:any;

 public baseUrl: string = "https://api.aicasindia.in/api/";
//public baseUrl: string = "http://localhost:2141/api/";

  constructor( private afs: AngularFirestore,
    public http:HttpClient
    ) { }
 
  //!   //////////////////////////////////////////////////////////////////////////////////////////////////////////

//get students for login 

getStudent(data){
  return this.http.post(this.baseUrl+"students/login",data);
}

getMaterials(data){
  return this.http.post(this.baseUrl+"materials/get_materials",data);
}
getQuestion(language){
  return this.http.post(this.baseUrl + "questions/get_question", language);
}
saveExamAnswers(data){
  return this.http.post(this.baseUrl + "answers/add_answers", data);
}
saveExamWithStatusUpdate(data){
  return this.http.post(this.baseUrl+"students/update",data);
}
getOneStudentWithExamDetail(data){
  return this.http.post(this.baseUrl+"students/getStudent_byFranchises_withExamDetail",data);
 
}
  }

